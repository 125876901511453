body {
  margin: 0;
  font-family: 'Lato', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(167, 167, 167);
    padding-right: 10px;
    border-radius: 5px;
    padding-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(124, 124, 124);
  }

  * {
    scrollbar-color: #888 #f0f0f0;
    scrollbar-width: thin;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
